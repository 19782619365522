import React, { useCallback, useEffect, useRef, useState } from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './Chatbot.css';

const Chatbot: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState(0); 
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [country, setCountry] = useState(''); 
  const [questions, setQuestions] = useState<string[]>([]); 
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false); 
  const [isMobileValid, setIsMobileValid] = useState(false); 
  const [emailTouched, setEmailTouched] = useState(false); 

  const nameInputRef = useRef<HTMLInputElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const mobileInputRef = useRef<HTMLInputElement>(null);
  const questionInputRef = useRef<HTMLTextAreaElement>(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    if (!isOpen && nameInputRef.current) {
      setTimeout(() => nameInputRef.current?.focus(), 0); 
    }
  };

  // Wrap in useCallback to stabilize the function
  const handleNextStep = useCallback(() => {
    if (step === 0 && name) {
      setStep(step + 1);
    } else if (step === 1 && isEmailValid) {
      setStep(step + 1);
    } else if (step === 2 && isMobileValid) {
      setStep(step + 1);
    }
  }, [step, name, isEmailValid, isMobileValid]);

  const askAnotherQuestion = () => {
    setSubmitted(false);
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateMobile = (mobile: string) => {
    const phoneRegex = /^[0-9]{10,15}$/; 
    return phoneRegex.test(mobile);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    setIsEmailValid(validateEmail(inputEmail));
  };

  const handleEmailFocus = () => {
    setEmailTouched(true);
  };

  const handleMobileChange = (value: string, data: CountryData) => {
    setMobile(value);
    setCountry(data.name);
    setIsMobileValid(validateMobile(value));
  };

  // Wrap in useCallback to stabilize the function
  const handleSubmitQuestion = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    const newQuestions = [...questions, currentQuestion];
    setQuestions(newQuestions); 

    try {
      const response = await fetch('/api/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          email,
          mobile,
          country, 
          questions: newQuestions,
        }),
      });

      if (response.ok) {
        setCurrentQuestion(''); 
        setSubmitted(true);
      } else {
        console.error('Failed to submit');
      }
    } catch (error) {
      console.error('Error submitting the form:', error);
    }
  }, [name, email, mobile, country, questions, currentQuestion]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault(); 
        if (step === 0 && name) {
          handleNextStep();
        } else if (step === 1 && isEmailValid) {
          handleNextStep();
        } else if (step === 2 && isMobileValid) {
          handleNextStep();
        } else if (step === 3 && currentQuestion) {
          handleSubmitQuestion(new Event('submit') as unknown as React.FormEvent);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [step, name, email, isEmailValid, mobile, isMobileValid, currentQuestion, handleNextStep, handleSubmitQuestion]);

  useEffect(() => {
    if (step === 0 && nameInputRef.current) {
      nameInputRef.current.focus();
    } else if (step === 1 && emailInputRef.current) {
      emailInputRef.current.focus();
    } else if (step === 2 && mobileInputRef.current) {
      mobileInputRef.current.focus();
    } else if (step === 3 && questionInputRef.current) {
      questionInputRef.current.focus();
    }
  }, [step]);

  return (
    <div className={`chatbot-container ${isOpen ? 'open' : ''}`}>
      <div className="chatbot-header" onClick={handleToggle}>
        <h4>Chat with us!</h4>
      </div>
      {isOpen && (
        <div className="chatbot-body">
          {submitted ? (
            <div className="chatbot-message">
              <p>Thank you for your question! We will email you back with the answer.</p>
              <button
                type="button"
                className="chatbot-btn"
                onClick={askAnotherQuestion}
              >
                Ask another question
              </button>
            </div>
          ) : (
            <form onSubmit={handleSubmitQuestion}>
              {step === 0 && (
                <div className="chatbot-message">
                  <p>Hi! What’s your name?</p>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="chatbot-input"
                    placeholder="Enter your name"
                    required
                    ref={nameInputRef}
                  />
                  <button
                    type="button"
                    className="chatbot-btn"
                    onClick={handleNextStep}
                    disabled={!name}
                  >
                    Next
                  </button>
                </div>
              )}

              {step === 1 && (
                <div className="chatbot-message">
                  <p>Great, {name}! What’s your email?</p>
                  <input
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    onFocus={handleEmailFocus}
                    className="chatbot-input"
                    placeholder="Enter your email"
                    required
                    ref={emailInputRef}
                  />
                  {!isEmailValid && emailTouched && email.length > 0 && (
                    <p className="email-error">Please enter a valid email address.</p>
                  )}
                  <button
                    type="button"
                    className="chatbot-btn"
                    onClick={handleNextStep}
                    disabled={!isEmailValid}
                  >
                    Next
                  </button>
                </div>
              )}

              {step === 2 && (
                <div className="chatbot-message">
                  <p>What's your mobile number?</p>
                  <PhoneInput
                    country={'us'}
                    value={mobile}
                    onChange={handleMobileChange}
                    inputClass="chatbot-input"
                    enableSearch={true}
                    inputProps={{
                      ref: mobileInputRef,
                    }}
                  />
                  {!isMobileValid && mobile.length > 0 && (
                    <p className="mobile-error">Please enter a valid mobile number.</p>
                  )}
                  <button
                    type="button"
                    className="chatbot-btn"
                    onClick={handleNextStep}
                    disabled={!isMobileValid}
                  >
                    Next
                  </button>
                </div>
              )}

              {step >= 3 && (
                <div className="chatbot-message">
                  <p>What question do you have?</p>
                  <textarea
                    value={currentQuestion}
                    onChange={(e) => setCurrentQuestion(e.target.value)}
                    className="chatbot-input"
                    placeholder="Enter your question"
                    rows={3}
                    required
                    ref={questionInputRef}
                  />
                  <button
                    type="submit"
                    className="chatbot-btn"
                    disabled={!currentQuestion}
                  >
                    Submit
                  </button>
                </div>
              )}

              {questions.length > 0 && (
                <div className="chatbot-message">
                  <h5>Previous Questions:</h5>
                  <ul className="questions-list">
                    {questions.map((q, index) => (
                      <li key={index}>{q}</li>
                    ))}
                  </ul>
                </div>
              )}
            </form>
          )}
        </div>
      )}

      {!isOpen && (
        <div className="chatbot-button" onClick={handleToggle}>
          <span role="img" aria-label="chat bubble">💬</span>
        </div>
      )}
    </div>
  );
};

export default Chatbot;
