// src/pages/About.tsx
import React from 'react';
import './About.css';

const About: React.FC = () => {
  return (
    <div className="about">
      <h1>About Agri Twin</h1>
      <section className="mission">
        <h2>Our Mission</h2>
        <p>At Agri Twin, we're on a mission to revolutionize agriculture through the power of artificial intelligence and genomics. We create digital twins of crops and livestock, enabling farmers and agribusinesses to make data-driven decisions that optimize yields, reduce environmental impact, and ensure food security for future generations.</p>
      </section>
      <section className="team">
        <h2>Our Team</h2>
        <div className="team-grid">
          <div className="team-member">
            <img src="/api/placeholder/150/150" alt="Jane Doe" />
            <h3>Jane Doe</h3>
            <p>CEO & Co-founder</p>
          </div>
          <div className="team-member">
            <img src="/api/placeholder/150/150" alt="John Smith" />
            <h3>John Smith</h3>
            <p>CTO & Co-founder</p>
          </div>
          <div className="team-member">
            <img src="/api/placeholder/150/150" alt="Emily Chen" />
            <h3>Emily Chen</h3>
            <p>Lead AI Scientist</p>
          </div>
          <div className="team-member">
            <img src="/api/placeholder/150/150" alt="Michael Johnson" />
            <h3>Michael Johnson</h3>
            <p>Head of Genomics</p>
          </div>
        </div>
      </section>
      <section className="values">
        <h2>Our Values</h2>
        <ul>
          <li>Innovation: Pushing the boundaries of what's possible in AgriTech</li>
          <li>Sustainability: Promoting environmentally responsible farming practices</li>
          <li>Collaboration: Partnering with farmers, researchers, and industry leaders</li>
          <li>Integrity: Maintaining the highest standards of data security and ethical AI use</li>
        </ul>
      </section>
      <section className="achievements">
        <h2>Our Achievements</h2>
        <ul>
          <li>Developed the world's first AI-powered digital twin for corn crops</li>
          <li>Reduced water usage by 30% in partner farms through precision irrigation</li>
          <li>Increased livestock health and productivity by 25% using predictive analytics</li>
          <li>Published groundbreaking research on genomic markers for drought resistance</li>
        </ul>
      </section>
    </div>
  );
}

export default About;