// src/pages/Services.tsx
import React from 'react';
import './Services.css';

const Services: React.FC = () => {
  return (
    <div className="services">
      <h1>Our Services</h1>
      <div className="service-grid">
        <div className="service-item">
          <h2>Crop Digital Twin</h2>
          <p>Create virtual models of your crops to simulate growth, predict yields, and optimize resource allocation.</p>
        </div>
        <div className="service-item">
          <h2>Livestock Digital Twin</h2>
          <p>Monitor and manage your livestock with AI-driven insights, improving health and productivity.</p>
        </div>
        <div className="service-item">
          <h2>Precision Agriculture Solutions</h2>
          <p>Implement targeted farming techniques based on real-time data and predictive analytics.</p>
        </div>
        <div className="service-item">
          <h2>Genomic Analysis</h2>
          <p>Utilize advanced genomic sequencing to enhance crop resilience and livestock breeding programs.</p>
        </div>
      </div>
    </div>
  );
}

export default Services;