// pages/Home.tsx
import React, { useEffect, useState } from 'react';
import './ImageFade.css'; // Import the CSS for the fade animation

const DigitalTwinHomepage: React.FC = () => {
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    // Start the looped fade animation
    const interval = setInterval(() => {
      setIsFading((prev) => !prev); // Toggle between true and false
    }, 6000); // 4s for fade transition + 2s delay (total 6 seconds)

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="digital-twin-homepage min-h-screen bg-black py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">

        {/* Image Fade Section */}
        <div className="relative image-fade-container">
          {/* Bottom Image (Original) */}
          <img
            src="/Original.webp" // Path to the original image
            alt="Original Cows"
            className={`fade-image bottom-image ${isFading ? 'fade-out' : 'fade-in'}`}
          />

          {/* Top Image (Digital) */}
          <img
            src="/Digital.webp" // Path to the digital twin image
            alt="Digital Twin Cows"
            className={`fade-image top-image ${isFading ? 'fade-in' : 'fade-out'}`}
          />


          {/* Floating Banner */}
          {/* <div className="floating-banner">
            Agri Twin creates the digital twin of your herd to improve efficiency and profitability
          </div> */}
                   {/* Text Overlay */}
                   <div className="overlay-text">
            It has never been better to be a Dairy Farmer
          </div>

        </div>

        {/* Text Overlay */}
        <h1 className="absolute top-4 left-1/2 transform -translate-x-1/2 text-4xl font-bold text-center text-white">
          <span className="text-black"></span>Revolutionising Agriculture with Digital Twin Technology
        </h1>

        {/* Description Section */}
        <div className="bg-gray-900 shadow-xl rounded-lg overflow-hidden mt-8">
          <div className="p-6">
            <p className="text-xl text-white mb-4 text-center">
              In the ever-evolving world of agriculture, <strong>Agri Twin</strong> is at the forefront, leveraging <strong>digital twin technology</strong> to transform both <strong>dairy farming</strong> and <strong>crop management</strong>. Our innovative solutions provide farmers with unprecedented control and insights by creating real-time digital replicas of their <strong>livestock</strong> and <strong>crops</strong>, driving <strong>efficiency</strong> and improving overall <strong>profitability</strong>.
            </p>
            {/* <div className="flex flex-col sm:flex-row justify-center items-center space-x-0 sm:space-x-4">
              <a href="/services" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4 sm:mb-0">
                Explore Our Services
              </a>
              <a href="#features" className="text-blue-500 hover:text-blue-700">
                See Features →
              </a>
            </div> */}
          </div>
        </div>

        {/* Additional Sections */}
        <h2 className="absolute top-4 left-1/2 transform -translate-x-1/2 text-4xl font-bold text-center text-white">
          How Agri Twin Creates Digital Twins for Livestock
        </h2>
        <div className="bg-gray-900 shadow-xl rounded-lg overflow-hidden mt-8">
          <div className="p-6">
            <p className="text-xl text-white mb-4 text-center">
              Agri Twin's digital twin technology for dairy cattle enables farmers to monitor, analyze, and optimize the health and productivity of their herds. By creating a virtual representation of each animal, farmers can track vital metrics, such as:
            </p>
            <ul className="no-bullets text-white">
              <li><strong>Health status</strong> and early disease detection</li>
              <li><strong>Milk production efficiency</strong></li>
              <li><strong>Feeding patterns</strong> and nutrition optimization</li>
              <li><strong>Behavioral patterns</strong> and movement analysis</li>
            </ul>
            <p className="text-xl text-white mb-4 text-center">
              Through advanced sensors, data collection, and AI algorithms, Agri Twin allows farmers to make data-driven decisions to improve herd management and dairy farm efficiency.
            </p>
          </div>
        </div>

        <h3 className="absolute top-4 left-1/2 transform -translate-x-1/2 text-4xl font-bold text-center text-white">
          Enhancing Crop Management with Digital Twin Solutions
        </h3>
        <div className="bg-gray-900 shadow-xl rounded-lg overflow-hidden mt-8">
          <div className="p-6">
            <p className="text-xl text-white mb-4 text-center">
              For crop management, Agri Twin's digital twin solution revolutionizes how farmers monitor and manage their fields. By creating a digital replica of the crops, farmers can:
            </p>
            <ul className="no-bullets text-white">
              <li>Track growth and health patterns of the plants</li>
              <li>Identify areas requiring irrigation or nutrients</li>
              <li>Monitor soil conditions and weather data in real-time</li>
              <li>Detect early signs of pest infestations or disease</li>
            </ul>
          </div>
        </div>

        <h2 className="absolute top-4 left-1/2 transform -translate-x-1/2 text-4xl font-bold text-center text-white">
          Boosting Dairy Farm Efficiency and Profitability
        </h2>
        <div className="bg-gray-900 shadow-xl rounded-lg overflow-hidden mt-8">
          <div className="p-6">
            <p className="text-xl text-white mb-4 text-center">
              By integrating digital twin technology for both livestock and crops, Agri Twin provides farmers with a complete digital ecosystem that helps them monitor every aspect of their farm in real-time. This technology allows for:
            </p>
            <ul className="no-bullets text-white">
              <li><strong>Improved resource allocation</strong> – Knowing exactly what your herd or crops need</li>
              <li><strong>Data-driven decision making</strong> – Making informed choices based on real-time data</li>
              <li><strong>Reducing operational costs</strong> – By streamlining processes and avoiding potential issues</li>
              <li><strong>Maximizing yields</strong> – Both in terms of milk production and crop harvests</li>
            </ul>
          </div>
        </div>

        <h3 className="absolute top-4 left-1/2 transform -translate-x-1/2 text-4xl font-bold text-center text-white">
          Why Choose Agri Twin for Your Dairy and Crop Management?
        </h3>
        <div className="bg-gray-900 shadow-xl rounded-lg overflow-hidden mt-8">
          <div className="p-6">
            <p className="text-xl text-white mb-4 text-center">
              By choosing Agri Twin, you are investing in cutting-edge technology that takes your farm to the next level. Our system bridges the gap between physical livestock and crops and their digital counterparts, enabling you to manage your farm with unprecedented accuracy and efficiency.
            </p>
            <ul className="no-bullets text-white">
              <li><strong>Real-time insights:</strong> Always know the status of your farm.</li>
              <li><strong>Predictive analytics:</strong> Stay one step ahead of potential issues.</li>
              <li><strong>Increased profitability:</strong> Optimize production and reduce waste.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const Home: React.FC = () => {
  return (
    <div className="home">
      <DigitalTwinHomepage />
    </div>
  );
};

export default Home;
