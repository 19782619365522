// src/components/Header.tsx
import React, { useEffect, useState } from 'react';
import './Header.css'; // Import the CSS for the header

const Header: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
<header className={`header ${isScrolled ? 'scrolled' : ''}`}>
  <img
    src={isScrolled ? '/AgriTwinLogoBlackGrass.png' : '/AgriTwinLogoWhiteGrass.png'}
    alt="Agri Twin Logo"
    className="logo"
  />

  {/* Hamburger Icon for Mobile */}
  <div className="hamburger" onClick={toggleMenu}>
    <span className="bar"></span>
    <span className="bar"></span>
    <span className="bar"></span>
  </div>

  {/* Desktop Navigation */}
  <nav className="nav-links">
    <ul>
      <li><a href="#home" className="nav-link">Home</a></li>
      <li><a href="#about" className="nav-link">About</a></li>
      <li><a href="#services" className="nav-link">Services</a></li>
      <li><a href="#contact" className="nav-link">Contact</a></li>
    </ul>
  </nav>

  {/* Slide Menu for Mobile */}
  <nav className={`nav-menu ${isMenuOpen ? 'open' : ''}`}>
    <ul>
      <li><a href="#home" className="nav-link">Home</a></li>
      <li><a href="#about" className="nav-link">About</a></li>
      <li><a href="#services" className="nav-link">Services</a></li>
      <li><a href="#contact" className="nav-link">Contact</a></li>
    </ul>
  </nav>
</header>
  );
};

export default Header;
